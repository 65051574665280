<template>
  <div style="width: 100%" class="flexHV">
    <div style="width: 80%">
      <el-form :disabled="isShow" size="mini" :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="100px" class="demo-dynamic">
        <el-row :gutter="20">
          <el-col v-for="(domain, index) in dynamicValidateForm.domains" :key="index" :md="md">
            <el-form-item label-width="0" :key="domain.key" :prop="'domains.' + index + '.value'">
              <span slot="label" style="display: none"></span>
              <div class="flexHV">
                <el-input v-model="domain.value" @blur="$emit('blurChange', getArrayIds(dynamicValidateForm.domains, 'value', true))"></el-input>
                <el-button icon="el-icon-delete" @click.prevent="removeDomain(domain)"></el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div style="width: 20%" class="flexHV">
      <el-button :disabled="isShow" size="mini" icon="el-icon-plus" @click="addDomain"></el-button>
    </div>
  </div>
</template>
<script>
import { getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'SimpleDynamicForm',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    md: {
      type: Number,
      default: 8
    },
    paramData: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dynamicValidateForm: {
        domains: [
          {
            value: ''
          }
        ]
      }
    };
  },
  methods: {
    getArrayIds,
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item);
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1);
      }
      this.$emit('blurChange', getArrayIds(this.dynamicValidateForm.domains, 'value', true));
    },
    addDomain() {
      this.dynamicValidateForm.domains.push({
        value: '',
        key: Date.now()
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
