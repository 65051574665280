<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-card>
      <div v-sticky style="padding-top: 16px; background: white">
        <editHeader ref="editHeader" :isShow="isShow" :btn="btn" :strForm="optnForm" @openEdit="openEdit" @closeEdit="closeEdit" @submit="submit('optnForm')" />
        <el-form ref="optnForm" :model="optnForm" :rules="rules" :disabled="isShow" label-width="120px" size="mini" class="vg_pt_16 vd_mtb vg_mt_16">
          <el-row>
            <el-col :md="8">
              <el-form-item label="数据名称" prop="perm_name">
                <el-input v-model="optnForm.perm_name" placeholder="数据名称" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="vd_button vg_mb_8" style="display: flex">
            <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
            <el-button type="danger" plain size="small" @click="delTeam()">删除</el-button>
            <div class="flexHV vg_ml_8">
              <span>搜索: </span>
              <el-input
                v-model="searchBlur"
                placeholder="请输入"
                size="mini"
                style="width: 200px"
                @blur="search = searchBlur"
                @input="val => (!val ? (search = searchBlur) : '')"
              />
            </div>
          </div>
        </el-form>
      </div>
      <DynamicUTable
        ref="optnDynamicUTable"
        :columns="tableProperties"
        :is-show="isShow"
        :form="optnForm"
        :need-pagination="false"
        :table-data="globalSearch"
        :auto-height="autoHeight"
        :need-fixed-height="!autoHeight"
        @selection-change="handleSelectionChange"
        v-if="tableFlag"
        :row-move="true"
        :openDefaultCommonSlots="true"
      >
        <template v-slot:param1="scope" style="width: 100%; background-color: red">
          <el-form-item label-width="0" :prop="'optn_cntt_list.' + scope.$index + '.param1'" :rules="[{ required: true }]">
            <el-input
              style="width: 100%"
              @change="changeInput(scope)"
              v-model="scope.row.param1"
              :maxlength="maxlength1"
              @input="val => ([10018].includes(optnForm.perm_id) ? (scope.row.param1 = val.toUpperCase()) : '')"
              show-word-limit
            ></el-input>
          </el-form-item>
        </template>
        <template v-slot:param2="scope">
          <div style="max-height: 200px; overflow-y: auto">
            <SimpleDynamicForm
              v-if="[10032].includes(optnForm.perm_id)"
              :key="'param2' + scope.$index"
              :ref="'SimpleDynamicForm' + scope.row.k"
              :isShow="isShow"
              :md="12"
              :param-data="scope.row.param2"
              @blurChange="val => (scope.row.param2 = val)"
            ></SimpleDynamicForm>
            <el-form-item v-else-if="[10009].includes(optnForm.perm_id)" :rules="[{ required: true }]" label-width="0">
              <el-select v-model="scope.row.param2" filterable placeholder="请选择所属种类">
                <el-option v-for="item in speciesList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-else :rules="[{ required: true }]" label-width="0">
              <el-input
                v-model="scope.row.param2"
                :maxlength="maxlength2"
                show-word-limit
                @input="val => ([10003].includes(optnForm.perm_id) ? (scope.row.param2 = val.toUpperCase()) : '')"
              ></el-input>
            </el-form-item>
          </div>
        </template>
        <template v-slot:param3="scope">
          <div style="max-height: 200px; overflow-y: auto">
            <SimpleDynamicForm
              v-if="[10032].includes(optnForm.perm_id)"
              :key="'param3' + scope.$index"
              :ref="'SimpleDynamicFormTh' + scope.row.k"
              :isShow="isShow"
              :md="24"
              @blurChange="val => (scope.row.param3 = val)"
            ></SimpleDynamicForm>
            <el-form-item v-else :rules="[{ required: true }]" label-width="0">
              <el-input v-model="scope.row.param3" :maxlength="maxlength3" show-word-limit></el-input>
            </el-form-item>
          </div>
        </template>
        <template v-slot:param4="scope">
          <div style="max-height: 200px; overflow-y: auto">
            <SimpleDynamicForm
              v-if="[10032].includes(optnForm.perm_id)"
              :key="'param4' + scope.$index"
              :ref="'SimpleDynamicFormFour' + scope.row.k"
              :isShow="isShow"
              :md="24"
              @blurChange="val => (scope.row.param4 = val)"
            ></SimpleDynamicForm>
            <el-form-item v-else :rules="[{ required: true }]" label-width="0">
              <el-input v-model="scope.row.param4" :maxlength="maxlength4" show-word-limit></el-input>
            </el-form-item>
          </div>
        </template>
        <template v-slot:param5="scope">
          <el-form-item label-width="0" :rules="[{ required: true }]">
            <el-input v-model="scope.row.param5" :maxlength="maxlength5" show-word-limit></el-input>
          </el-form-item>
        </template>
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { optnAPI } from '@api/modules/optn';
import editHeader from '@/views/component/editHeader';
import SimpleDynamicForm from '@/components/table/SimpleDynamicForm.vue';
import { getArrayIds } from '@assets/js/arrayUtils';
import DynamicUTable from '@/components/table/DynamicUTable.vue';

export default {
  name: 'OptnEdit',
  components: {
    DynamicUTable,
    SimpleDynamicForm,
    editHeader
  },
  data() {
    return {
      optnForm: {
        optn_id: '',
        perm_id: '',
        perm_name: '',
        param1: '',
        param2: null,
        param3: null,
        param4: null,
        param5: null,
        optn_cntt_list: []
      },
      rules: {
        perm_name: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      speciesList: [
        { label: '毛绒', value: '毛绒' },
        { label: '辅材', value: '辅材' },
        { label: '非毛绒', value: '非毛绒' }
      ],
      autoHeight: false,
      key: 0,
      optn_cntt_copy: [],
      selectionsList: [],
      isShow: true,
      btn: {},
      copyForm: [],
      loadingFlag: true,
      search: '',
      searchBlur: '',
      keys: ['param1', 'param2', 'param3', 'param4', 'param5'],
      tableProperties: [
        {
          label: '',
          prop: 'param1',
          itemType: 'input',
          input: false,
          sortable: false,
          widthAuto: true,
          subItem: {
            type: 'input'
          }
        },
        { label: '', prop: 'param2', itemType: 'input', input: false, sortable: false, widthAuto: true, visible: true },
        { label: '', prop: 'param3', itemType: 'input', input: false, sortable: false, widthAuto: true, visible: true },
        { label: '', prop: 'param4', itemType: 'input', input: false, sortable: false, widthAuto: true, visible: true },
        { label: '', prop: 'param5', itemType: 'input', input: false, sortable: false, widthAuto: true, visible: true }
      ],
      tableFlag: false,
      sortList: [],
      maxlength1: 30,
      maxlength2: 30,
      maxlength3: 30,
      maxlength4: 30,
      maxlength5: 30
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    globalSearch() {
      let result = [];
      if (this.search) {
        result = this.optnForm.optn_cntt_list.filter(item => {
          return this.keys.some(val => {
            return String(item[val]).toLowerCase().includes(this.search.toLowerCase());
          });
        });
      } else {
        result = this.optnForm.optn_cntt_list;
      }
      setTimeout(() => {
        this.updateData();
      });
      return result;
    }
  },
  methods: {
    initData() {
      if (this.$route.query.perm_id) {
        //配置字段长度
        switch (this.$route.query.perm_id) {
          case '10047': //财务客户对应关系
            this.maxlength1 = 50;
            this.maxlength2 = 50;
            this.maxlength3 = 50;
            this.maxlength4 = 50;
            this.maxlength5 = 50;
            break;
          case '10013': //付款方式
            this.maxlength1 = 100;
            break;
        }
        this.loadingFlag = true;
        this.getOptnInfo();
      }
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //获取表单信息
    getOptnInfo() {
      this.tableFlag = false;
      getNoCatch(optnAPI.getOptnByPermId, { perm_id: this.$route.query.perm_id, ...this.searchForm }).then(({ data }) => {
        this.optnForm = data.form;
        this.autoHeight = [10032].includes(this.optnForm.perm_id);
        this.tableProperties[0].label = this.optnForm.param1;
        this.optnForm.param2 ? (this.tableProperties[1].label = this.optnForm.param2) : (this.tableProperties[1].visible = false);
        this.optnForm.param3 ? (this.tableProperties[2].label = this.optnForm.param3) : (this.tableProperties[2].visible = false);
        this.optnForm.param4 ? (this.tableProperties[3].label = this.optnForm.param4) : (this.tableProperties[3].visible = false);
        this.optnForm.param5 ? (this.tableProperties[4].label = this.optnForm.param5) : (this.tableProperties[4].visible = false);
        this.btn = data.btn;
        this.optnForm.optn_cntt_list.forEach((item, index) => {
          item.k = this.key;
          this.key++;
        });
        this.optn_cntt_copy = this.optnForm.optn_cntt_list;
        this.copyForm = Object.assign(this.copyForm, this.optnForm.optn_cntt_list);
        setTimeout(() => {
          this.tableFlag = true;
          setTimeout(() => {
            this.updateData();
          }, 1000);
          this.loadingFlag = false;
        }, 500);
      });
    },
    //添加
    addRow() {
      let item = {
        param1: '',
        param2: '',
        param3: '',
        param4: '',
        param5: '',
        destroy_flag: 0,
        k: this.key
      };
      this.key = this.key + 1;
      this.optnForm.optn_cntt_list.push(item);
      this.selectionsList = [];
    },
    // 判断是否重复
    changeInput(val) {
      if ([10019, 10024, 10047].includes(this.optnForm.perm_id)) {
        return;
      }
      let arr = this.optnForm.optn_cntt_list.filter(item => {
        if (item.param1 === val.row.param1) {
          return item;
        }
      });
      if (arr.length >= 2) {
        this.optnForm.optn_cntt_list[val.$index].param1 = null;
        return this.$message.warning('数据重复，请重新填写');
      }
    },
    //多选
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //多选删除
    delTeam() {
      if (this.selectionsList.length > 0) {
        for (let i = 0; i < this.selectionsList.length; i++) {
          this.optnForm.optn_cntt_list.forEach(item => {
            if (item.k === this.selectionsList[i].k) {
              let currentIndex = this.optnForm.optn_cntt_list.indexOf(item);
              this.optnForm.optn_cntt_list.splice(currentIndex, 1);
            }
          });
        }
      } else {
        this.$message({
          type: 'warning',
          message: '至少选择一条数据'
        });
      }
    },
    //提交表单
    submit(formName) {
      let flag = this.optnForm.optn_cntt_list.some(item => {
        return item.param1 == '';
      });
      if (flag) {
        let name = '';
        return this.$message.error(this.optnForm.perm_name + '名称不可为空');
      }
      this.$confirm('确认保存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      this.optnForm.optn_id = this.$route.query.form_id;
      let optnForm = {};
      let optnFi = [];
      optnForm = Object.assign(optnForm, this.optnForm);
      optnFi = Object.assign(optnFi, this.optn_cntt_copy);
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        if (optnFi.indexOf(this.copyForm[i]) !== -1) {
          this.copyForm.splice(i, 1);
        }
      }
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        this.copyForm[i].destroy_flag = 1;
      }
      optnFi = optnFi.concat(this.copyForm);
      optnForm.optn_cntt_list = optnFi;
      if (this.optnForm.perm_id === 10032) {
        optnForm.optn_cntt_list.forEach((item, index) => {
          let ref1 = this.$refs['SimpleDynamicForm' + item.k];
          let ref2 = this.$refs['SimpleDynamicFormTh' + item.k];
          let ref3 = this.$refs['SimpleDynamicFormFour' + item.k];
          item.param2 = getArrayIds(ref1?.dynamicValidateForm.domains || [], 'value', true);
          item.param3 = getArrayIds(ref2?.dynamicValidateForm.domains || [], 'value', true, '/*/');
          item.param4 = getArrayIds(ref3?.dynamicValidateForm.domains || [], 'value', true, '/*/');
        });
      }
      postNoCatch(optnAPI.editContent, optnForm).then(res => {
        this.$message({
          type: 'success',
          message: '保存成功'
        });
        this.initData();
        this.isShow = true;
      });
    },
    updateData() {
      if (this.optnForm.perm_id === 10032) {
        this.optnForm.optn_cntt_list.forEach(item => {
          let ref1 = this.$refs['SimpleDynamicForm' + item.k];
          let ref2 = this.$refs['SimpleDynamicFormTh' + item.k];
          let ref3 = this.$refs['SimpleDynamicFormFour' + item.k];
          if (ref1) {
            ref1.dynamicValidateForm.domains = Array.from(item.param2?.split(',') || [], x => {
              return { value: x };
            });
          }
          if (ref2) {
            ref2.dynamicValidateForm.domains = Array.from(item.param3?.split('/*/') || [], x => {
              return { value: x };
            });
          }
          if (ref3) {
            ref3.dynamicValidateForm.domains = Array.from(item.param4?.split('/*/') || [], x => {
              return { value: x };
            });
          }
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}

.vd_mtb {
  border-top: #c0c4cc solid 1px;
}

::v-deep .el-card__body {
  height: calc(100vh - 145px);
  overflow-y: auto;
  padding-top: 0;
}
</style>
