import { render, staticRenderFns } from "./SimpleDynamicForm.vue?vue&type=template&id=771c7616&scoped=true&"
import script from "./SimpleDynamicForm.vue?vue&type=script&lang=js&"
export * from "./SimpleDynamicForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "771c7616",
  null
  
)

export default component.exports